import React from 'react';
import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
import { Box } from '../../provider';
import Markdown from 'react-markdown';

const PostsListItem = ({ data, returnPath = '/' }) => {
  return (
    <Box>
      <GatsbyLinkButton to={returnPath}>Posts</GatsbyLinkButton>
      <Box sx={{ mt: '20px' }}>{data.post.frontmatter.title}</Box>
      <Box
        sx={{
          fontSize: [1, 2, 3],
          '& p': { pl: '20px', pb: ['15px', '20px', '30px'] },
          ul: { pl: '40px', pb: ['15px', '20px', '30px'] },
          '& a': {
            appearance: 'none',
            border: 0,
            borderRadius: '0.325em',
            bg: 'primary',
            color: 'navtext',
            minWidth: '6.5em',
            padding: '0.5em 1em',
            fontFamily: 'inherit',
            fontSize: ['0.8rem', '0.9rem', '1rem'],
            cursor: 'pointer',
            transition: '0.3s ease-out',
            textDecoration: 'none',
            '&:hover': {
              bg: 'secondary',
            },
            '&:focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px #cccccc`,
            },
            '&:disabled': {
              color: 'background',
              bg: 'muted',
              cursor: 'not-allowed',
            },
          },
        }}
      >
        <Markdown>{data.post.rawBody}</Markdown>
      </Box>
      {false && <Box as="pre">{JSON.stringify(data, null, 2)}</Box>}
    </Box>
  );
};

export default PostsListItem;
